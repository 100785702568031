<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :api="url"></edit-dialog>
    </portal>
    <a-table-f-api
      v-if="url"
      ref="table"
      :api="url"
      :model="m.list"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect, genModel],
  components: {
    editDialog: () => import("./dialogs/projectDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",

      m: this.$store.getters["config/get"].models.team,
      m1: {
        list: [
          { name: "city", title: "Город", type: "string", sortable: true, width: 100 },
          { name: "department_block", title: "Блок", type: "string", sortable: true, width: 100 },
          { name: "department_department", title: "Департамент", type: "string", sortable: true, width: 100 },
          { name: "department_id", title: "Отдел", type: "select", dir: "department", sortable: true, width: 200 },
          { name: "name", title: "Имя", type: "string", sortable: true, width: 250 },
          { name: "job_id", title: "Должность", type: "select", dir: "job", sortable: true, width: 200 },
          { name: "phone", title: "Телефон", type: "string", sortable: true, width: 300 },
          { name: "email", title: "Email", type: "string", sortable: true, width: 300 },
        ],
      },
      url: "/accounting/dir/department_team",
      selectedRows: [],
      defaults_: {
        sort: [
          { key: "department_block", order: "asc" },
          { key: "department_department", order: "asc" },
          { key: "department_name", order: "asc" },
          { key: "name", order: "asc" },
        ],
        paramName: "team",
      },
      ddd: [
        { value: 1, text: "Трудовой договор" },
        { value: 2, text: "Договор о сотрудничестве" },
      ],
    };
  },
  created() {
    this.$root.title = "Команда";
  },
  computed: {
    defaults() {
      return this.m?.defaults?.list || this.defaults_;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      return;
      this.$router.push({ name: "projects_view", params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
